import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1000.000000 1000.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1000.000000) scale(0.100000,-0.100000)">

<path d="M1775 6416 c-156 -49 -271 -161 -327 -317 l-23 -64 0 -775 c0 -709 2
-781 18 -851 54 -230 222 -436 446 -548 146 -73 264 -101 428 -101 351 0 666
189 812 487 54 112 71 193 80 383 6 140 11 171 29 207 34 65 73 87 162 91 66
3 79 1 110 -20 19 -13 46 -44 60 -68 l25 -45 5 -430 c5 -418 6 -431 27 -470
50 -93 119 -134 221 -135 100 0 170 44 223 140 11 20 15 116 19 455 5 425 5
430 28 477 35 71 71 92 162 96 68 3 79 1 117 -24 29 -20 49 -43 65 -78 22 -50
23 -61 28 -476 5 -414 6 -426 27 -466 44 -82 120 -126 215 -125 98 0 187 59
224 147 21 51 20 874 -1 975 -52 245 -192 391 -445 464 -50 15 -99 19 -215 20
-174 0 -248 -15 -371 -75 l-77 -37 -66 32 c-211 103 -462 115 -683 31 -66 -24
-141 -67 -153 -86 -11 -18 -35 -11 -93 29 -181 123 -353 168 -590 158 -125 -5
-221 -25 -304 -61 -21 -9 -40 -16 -43 -16 -3 0 -5 245 -5 545 l0 545 -47 -1
c-27 0 -66 -6 -88 -13z m708 -1468 c75 -35 156 -113 198 -191 32 -59 34 -68
34 -162 0 -88 -3 -107 -27 -158 -38 -80 -117 -161 -197 -200 -62 -30 -72 -32
-176 -32 -101 0 -115 2 -170 29 -77 38 -163 124 -201 201 -26 54 -29 70 -29
155 0 82 4 103 26 151 33 71 88 132 158 177 76 48 130 62 236 59 73 -2 101 -8
148 -29z"/>
<path d="M5568 5003 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M6024 4846 c-41 -18 -71 -58 -79 -106 -6 -34 -10 -40 -32 -40 -24 0
-25 -2 -18 -37 8 -46 12 -53 30 -53 12 0 15 -26 17 -127 l3 -128 52 -3 52 -3
3 128 3 128 33 3 c27 3 32 7 27 20 -3 9 -9 29 -12 45 -4 20 -11 27 -29 27 -14
0 -24 6 -24 14 0 21 28 46 51 46 16 0 19 7 19 50 0 50 0 50 -32 50 -18 -1 -47
-7 -64 -14z"/>
<path d="M7865 4848 c-41 -23 -46 -57 -13 -88 25 -23 46 -25 69 -7 30 23 32
55 5 82 -28 28 -33 29 -61 13z"/>
<path d="M7420 4665 c0 -197 6 -222 63 -272 66 -58 145 -67 226 -27 93 46 132
140 94 225 -41 90 -101 124 -209 117 l-63 -4 -3 66 -3 65 -52 3 -53 3 0 -176z
m262 -77 c24 -36 29 -52 23 -78 -7 -33 -52 -70 -85 -70 -48 0 -89 38 -90 83 0
61 48 100 108 86 19 -4 39 -14 44 -21z"/>
<path d="M5100 4589 l0 -241 123 4 c159 6 211 31 224 111 8 51 -3 86 -39 119
l-31 29 21 15 c34 24 47 82 28 127 -24 59 -74 77 -213 77 l-113 0 0 -241z
m191 141 c24 -13 25 -58 2 -76 -10 -7 -30 -14 -45 -14 -27 0 -28 2 -28 50 0
47 2 50 26 50 14 0 34 -5 45 -10z m19 -200 c24 -24 24 -29 4 -58 -11 -16 -25
-22 -55 -22 l-39 0 0 50 0 50 35 0 c22 0 43 -8 55 -20z"/>
<path d="M6540 4828 c0 -2 -1 -109 -2 -238 l-1 -235 61 -3 62 -3 0 113 0 112
43 -42 c23 -23 47 -42 53 -42 5 0 28 18 49 40 21 22 43 40 47 40 4 0 8 -50 8
-110 l0 -111 63 3 62 3 3 238 c1 155 -1 237 -8 237 -5 0 -20 -9 -32 -19 -115
-98 -185 -151 -195 -149 -6 2 -54 40 -107 86 -86 73 -105 88 -106 80z"/>
<path d="M7147 4705 c-129 -33 -185 -167 -113 -275 105 -159 366 -87 366 100
0 42 -19 90 -49 124 -41 47 -131 70 -204 51z m88 -99 c62 -26 74 -89 26 -137
-20 -20 -39 -29 -61 -29 -37 0 -90 47 -90 79 0 39 20 72 52 86 41 18 33 17 73
1z"/>
<path d="M5575 4693 c-68 -36 -105 -95 -105 -168 0 -56 18 -92 65 -133 53 -47
64 -49 250 -41 l80 4 3 90 c2 50 -2 107 -8 128 -14 47 -65 106 -107 123 -44
19 -141 17 -178 -3z m158 -115 c24 -25 28 -36 23 -61 -8 -41 -32 -65 -71 -73
-55 -10 -104 27 -105 79 0 40 17 67 53 82 41 18 65 11 100 -27z"/>
<path d="M6245 4696 c-127 -57 -142 -242 -24 -318 67 -44 157 -46 213 -5 18
13 17 15 -10 53 -27 37 -31 39 -54 27 -14 -7 -35 -13 -48 -13 -27 0 -87 54
-77 70 4 6 61 10 141 10 133 0 134 0 134 23 0 40 -43 111 -85 139 -33 22 -51
27 -100 27 -33 0 -73 -6 -90 -13z m133 -103 c3 -9 -12 -13 -52 -13 -52 0 -73
11 -43 24 22 9 90 1 95 -11z"/>
<path d="M8034 4695 c-57 -29 -69 -64 -69 -210 l0 -130 54 0 54 0 -2 100 c-1
56 3 113 8 129 9 23 16 27 43 24 l33 -3 3 -127 3 -128 54 0 55 0 0 118 c0 124
6 142 45 142 39 0 45 -18 45 -142 l0 -118 56 0 56 0 -4 138 c-3 123 -5 140
-26 169 -29 41 -79 57 -151 49 -62 -8 -68 -8 -161 -1 -47 4 -73 1 -96 -10z"/>
<path d="M7840 4525 l0 -176 50 3 51 3 1 170 1 170 -52 3 -51 3 0 -176z"/>
<path d="M6621 3934 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
